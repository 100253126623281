import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BG_transparent } from '../constants';
import ImgSurvey from  '../images/Hand-Paper-Frame.png';
import { useStyles } from './IntroductionGame';
import { NextButton } from './NextButton';
import { TranslationsWithMarkup } from './TranslationsWithMarkup';

type SurveyProps = {
    onStart: any,
    userid: string,
    survey: string
}


const survey_url_map = Object({
    pre:{
        de: "",
        en: ""
    },
    post:{
        de: "https://ww2.unipark.de/uc/mmara_LIT_Robopsychology_Lab__Jo/b459/?a=",
        en: "https://ww2.unipark.de/uc/mmara_LIT_Robopsychology_Lab__Jo/ed5a/?a="
    }
})
  
  
const EmbedSurvey = (props: SurveyProps) => {

    const onStart = () => {
        props.onStart();
    }

    const { t, i18n } = useTranslation();
    
    React.useEffect(() => {
        console.log(survey_url_map[props.survey][i18n.language]+props.userid)
        const urlParams = new URLSearchParams(window.location.search)
        const skipSurvey = urlParams.get('skipSurvey')
        
        if(skipSurvey === "true"){
            onStart();
            return;
        }


        //@ts-ignore
        const childWindow = document?.getElementById('myFrame')?.contentWindow;
        window.addEventListener('message', message => {
            if (message.source !== childWindow) {
                return; // Skip message in this event listener
            }
            if(message.data["done"]){
                onStart();
            }
        });
    }, []);

    // skip pre survey
    return props.survey !== "pre" ? <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: BG_transparent
      }}>
          <div
            style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url("${ImgSurvey}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
            }}
        >
            <iframe id="myFrame" 
                src={survey_url_map[props.survey][i18n.language]+props.userid}
                height="80%"
                width="65%"
                title="Survey"
                style={{
                    marginTop: '8vh',
                    marginLeft: '27vw',
                    border: 'none',
                }}
            ></iframe>
            {/* TODO: remove button -> automatically go to next page when survey is completed */}
            {/* <NextButton onClick={onStart}
            style={{
                position: 'absolute',
                left: '4vh',
                bottom: '4vh'
            }}
            ></NextButton> */}

      </div>
        
    </div>: <DatenschutzScreen onNext={onStart}></DatenschutzScreen>
};


const DatenschutzScreen = (props: {onNext: any}) => {
    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.infoText} >
            <div style={{textAlign: 'center', paddingTop: '4vh', paddingLeft: '4vh', paddingRight: '4vh'}}>
                <Typography style={{ fontSize: '4.5vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"datenschutzHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 100 }}><TranslationsWithMarkup txtkey={"datenschutzText"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '3.0vh', marginTop: '3vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"teilnahmeHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 700 }}><TranslationsWithMarkup txtkey={"teilnahmeText"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '3.0vh', marginTop: '3vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"einwilligungHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 700 }}><TranslationsWithMarkup txtkey={"einwilligungText"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '3.0vh', marginTop: '3vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"kontaktHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 700 }}><TranslationsWithMarkup txtkey={"kontaktText"}></TranslationsWithMarkup></Typography>
            </div>
        <NextButton style={{
            position: 'absolute',
            right: '4vh',
            bottom: '4vh'
        }} onClick={props.onNext}></NextButton>
        </div>
    </div>
}

export default EmbedSurvey;

