import Pilz01 from '../images/pilze/pilz-01.png';
import Pilz02 from '../images/pilze/pilz-02.png';
import Pilz03 from '../images/pilze/pilz-03.png';
import Pilz04 from '../images/pilze/pilz-04.png';
import Pilz05 from '../images/pilze/pilz-05.png';
import Pilz06 from '../images/pilze/pilz-06.png';
import Pilz07 from '../images/pilze/pilz-07.png';
import Pilz08 from '../images/pilze/pilz-08.png';
import Pilz09 from '../images/pilze/pilz-09.png';
import Pilz10 from '../images/pilze/pilz-10.png';
import Pilz11 from '../images/pilze/pilz-11.png';
import Pilz12 from '../images/pilze/pilz-12.png';
import Pilz13 from '../images/pilze/pilz-13.png';
import Pilz14 from '../images/pilze/pilz-14.png';
import Pilz15 from '../images/pilze/pilz-15.png';


// import expl_03_DE from '../images/pilze/expl_03_DE.png';
// import expl_04_DE from '../images/pilze/expl_04_DE.png';
// import expl_05_DE from '../images/pilze/expl_05_DE.png';
// import expl_07_DE from '../images/pilze/expl_07_DE.png';
// import expl_08_DE from '../images/pilze/expl_08_DE.png';
// import expl_09_DE from '../images/pilze/expl_09_DE.png';
// import expl_12_DE from '../images/pilze/expl_012_DE.png';
// import expl_13_DE from '../images/pilze/expl_013_DE.png';
// import expl_14_DE from '../images/pilze/expl_014_DE.png';
// import expl_15_DE from '../images/pilze/expl_015_DE.png';
// import expl_03_EN from '../images/pilze/expl_03_EN.png';
// import expl_04_EN from '../images/pilze/expl_04_EN.png';
// import expl_05_EN from '../images/pilze/expl_05_EN.png';
// import expl_07_EN from '../images/pilze/expl_07_EN.png';
// import expl_08_EN from '../images/pilze/expl_08_EN.png';
// import expl_09_EN from '../images/pilze/expl_09_EN.png';
// import expl_12_EN from '../images/pilze/expl_012_EN.png';
// import expl_13_EN from '../images/pilze/expl_013_EN.png';
// import expl_14_EN from '../images/pilze/expl_014_EN.png';
// import expl_15_EN from '../images/pilze/expl_015_EN.png';

// const DE = [
//   null,
//   null,
//   expl_03_DE,
//   expl_04_DE,
//   expl_05_DE,
//   null,
//   expl_07_DE,
//   expl_08_DE,
//   expl_09_DE,
//   null,
//   null,
//   expl_12_DE,
//   expl_13_DE,
//   expl_14_DE,
//   expl_15_DE
// ]

// const EN = [
//   null,
//   null,
//   expl_03_EN,
//   expl_04_EN,
//   expl_05_EN,
//   null,
//   expl_07_EN,
//   expl_08_EN,
//   expl_09_EN,
//   null,
//   null,
//   expl_12_EN,
//   expl_13_EN,
//   expl_14_EN,
//   expl_15_EN
// ]

export type SinglePredictionType = {
  probability: number,
  class: string,
  nearestImage: string
}

export type User = {
  id: string,
  finished: boolean,
  explainer: boolean,
  newlyCreated: boolean
}


export type PredictionType = {

  images: any;
  prediction: {
    id: number;
    predictions: SinglePredictionType[],
    image: string,
    gradCam: [][],
    gradCamImg: any,
    class: string
  }
  step: number;
}

export const giftig = 'giftig'
export const essbar = 'essbar'

export const MushroomLabels: { [id: string]: string; } = {
  'Perlpilz': essbar,
  'Pantherpilz': giftig,
  'Parasol': essbar,
  'Steinpilz': essbar,
  'Gallenröhrling': giftig,
  'Wiesenchampignon': essbar,
  'Kegelhütiger Knollenblätterpilz': giftig,
  'Grüner Knollenblätterpilz': giftig,
  'Gifthäubling': giftig,
  'Stockschwämmchen': essbar,
  'Frühjahrslorchel': giftig,
  'Rundmorchel': essbar,
  'Lila Lackpilz': essbar,
  'Rettichhelmling': giftig,
  'Eierschwammerl': essbar,
  'Falscher Pfifferling': giftig,
  'Grüngelbes Gallertkäppchen': giftig,
  'Trompetenpfifferling': essbar
}







export function getHandy(id: number, language: string, explainer: boolean) {
  const expl = explainer ? "expl" : "noexpl";
  const lang = language === "de" ? "DE" : "EN";
  return require(`../images/pilze/${expl}_0${id}_${lang}.png`).default
  // if (language === 'de') {
  //   return DE[id - 1]
  // } else {
  //   return EN[id - 1]
  // }
}



export function getMushroomImage(id: number, language: string) {
  switch (id) {
    case 1:
      return Pilz01;
    case 2:
      return Pilz02;
    case 3:
      return Pilz03;
    case 4:
      return Pilz04;
    case 5:
      return Pilz05;
    case 6:
      return Pilz06;
    case 7:
      return Pilz07;
    case 8:
      return Pilz08;
    case 9:
      return Pilz09;
    case 10:
      return Pilz10;
    case 11:
      return Pilz11;
    case 12:
      return Pilz12;
    case 13:
      return Pilz13;
    case 14:
      return Pilz14;
    case 15:
      return Pilz15;
    default:
      return Pilz01;
  }
}