import { Box, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Essbar from '../icons/icon-essbar.svg';
import Giftig from '../icons/icon-giftig.svg';
import { giftig, MushroomLabels, PredictionType, SinglePredictionType } from "../types/types";
import { green, red } from "./ExplainerSummary";



const useStyles = makeStyles({
    item: {
        display: 'flex',
        background: '#424242',
        paddingRight: 16,
        border: '1px solid rgba(255, 255, 255, 0.12)',
        borderRadius: 4,
        marginTop: 4,
        marginBottom: 4
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-around',
        height: '15vh',
        paddingTop: '1vh',
        paddingBottom: '1vh'
    },
    avatar: {
        paddingRight: '1rem'
    }
});


type LinearProgressProps = {
    value: number
}


const StyledProgressWithLabel = withStyles((theme) => ({
    root: {
        height: '1vh',
        borderRadius: '0.5vh',
    },
    colorPrimary: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    bar: {
        borderRadius: '0.5vh',
        backgroundColor: '#fff',
    },
}))(LinearProgress);


function LinearProgressWithLabel({ value }: LinearProgressProps) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <StyledProgressWithLabel variant="determinate" value={value} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}



type MushroomItemProps = {
    prediction: SinglePredictionType
    choice: PredictionType
    explainer: boolean
}

export function MushroomItem({ prediction, choice, explainer }: MushroomItemProps) {
    const classes = useStyles();

    const label = MushroomLabels[prediction.class] as string

    const { t } = useTranslation();


    return <div className={classes.item}>
        {explainer ?
            <div className={classes.avatar}>
                <div>
                    <img alt={t(prediction.class)}
                        style={{
                            objectFit: 'cover',
                            width: '15vh',
                            height: '15vh',
                            display: 'block'
                        }}
                        src={'data:image/jpeg;base64,' + choice.images[prediction.nearestImage]}
                    ></img>
                </div>
            </div> : <div style={{ width: '1rem' }}></div>}
        <div className={classes.innerContainer}>
            <Typography variant="body1" style={{ fontSize: 14 }}>{t(prediction.class)}</Typography>
            <LinearProgressWithLabel value={prediction.probability * 100.0}></LinearProgressWithLabel>

            <div style={{ display: 'flex', alignItems: 'center', height: '3vh' }}>
                <img alt={t(label)} src={label === giftig ? Giftig : Essbar} style={{ width: '3vh', height: '3vh', marginRight: '1vh' }}></img>
                <Typography variant="body1" style={{ color: label === giftig ? red : green }}>{t(label)}</Typography>
            </div>
        </div>
    </div>
}