import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress, Typography, withStyles } from '@material-ui/core';
import { essbar, MushroomLabels, PredictionType } from '../types/types';
import { useTranslation } from 'react-i18next';

export const green = 'rgb(154, 222, 0)'
export const red = 'red' //"#9ade00"

const useStyles = makeStyles({
    mushroomList: {
        display: 'grid',
        gridTemplateAreas: `
        "lt n rt"
        "lb s rb"
        `,
        gridTemplateColumns: '5rem 1fr 5rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        paddingBottom: '0.5rem',
        paddingTop: '0.5rem',
        paddingLeft: '1vh',
        paddingRight: '1vh',
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    },
    mushroom: {
        background: 'red'
    }
});


const CorrectProgress = withStyles((theme) => ({
    root: {
        height: '1.5vh'
    },
    colorPrimary: {
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
    },
    bar: {
        backgroundColor: green
    },
}))(LinearProgress);

const WrongProgress = withStyles((theme) => ({
    root: {
        height: '1.5vh'
    },
    colorPrimary: {
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
    },
    bar: {
        backgroundColor: red
    },
}))(LinearProgress);

type LinearProgressProps = {
    value: number
    style: any
}

function CorrectProgressComp({ value, style }: LinearProgressProps) {
    return (
        <Box display="flex" alignItems="center" style={style}>
            <Box width="100%" mr={1}>
                <CorrectProgress variant="determinate" value={value} />
            </Box>
        </Box>
    );
}

function WrongProgressComp({ value, style }: LinearProgressProps) {
    return (
        <Box display="flex" alignItems="center" style={style}>
            <Box width="100%" mr={1}>
                <WrongProgress variant="determinate" value={value} />
            </Box>
        </Box>
    );
}

type SummaryProps = {
    choice?: PredictionType
}

export function ExplainerSummary({ choice }: SummaryProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    if (choice === undefined) return null;

    const edible_pct = choice.prediction.predictions.reduce((a, b) => {
        const label = MushroomLabels[b.class] as string
        if(label === essbar){
            return a + b.probability
        }else{
            return a
        }
        // if (b.class === choice.prediction.class) {
        //     return a + b.probability
        // } else {
        //     return a
        // }
    }, 0)

    const poisonous_pct = 1.0 - edible_pct

    return <div className={classes.mushroomList}>
        <Typography style={{
            gridArea: "lt",
            color: green,
            fontSize: 14
        }}>{t("essbar")}</Typography>
        <Typography variant="body1" style={{
            gridArea: "lb",
            color: red,
            fontSize: 14
        }}>{t("giftig")}</Typography>


        <CorrectProgressComp value={edible_pct * 100} style={{
            gridArea: 'n'
        }}></CorrectProgressComp>

        <WrongProgressComp value={poisonous_pct * 100} style={{
            gridArea: 's'
        }}></WrongProgressComp>

        <Typography variant='body1' style={{
            gridArea: 'rt',
            color: green,
            textAlign: 'end',
            fontSize: 14
        }}>{`${Math.round(edible_pct * 100)}%`}</Typography>

        <Typography variant='body1' style={{
            gridArea: 'rb',
            color: red,
            textAlign: 'end',
            fontSize: 14
        }}>{`${Math.round(poisonous_pct * 100)}%`}</Typography>
    </div>
}