
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TakePhoto } from '../App';
import { BG_transparent, BG_transparent_light } from '../constants';
import BtnNext2 from  '../images/Button-White-2.png';
import { NextButton } from './NextButton';

import goal_DE from '../images/introduction-screens/goal_DE.png';
import goal_EN from '../images/introduction-screens/goal_EN.png';
import scan_DE from '../images/introduction-screens/scan_DE.png';
import scan_EN from '../images/introduction-screens/scan_EN.png';
import start_mushroom_DE from '../images/introduction-screens/start-mushroom_DE.png';
import start_mushroom_EN from '../images/introduction-screens/start-mushroom_EN.png';
import start_DE from '../images/introduction-screens/start-aec_DE.png';
import start_EN from '../images/introduction-screens/start-aec_EN.png';



type SurveyProps = {
    onStart: any,
    withExpl: boolean
}

enum AppState {
    GOAL,
    SCAN,
    APP,
    START,
    SCAN_START,
    LETS_GO
  }


export const useStyles = makeStyles({
    btnNext: {
        backgroundImage: `url("${BtnNext2}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: "fit-content",
        fontSize: '6vh',
        color: 'black',
        padding: "3vh",
        float: "right",
        cursor: "pointer",
    },
    infoText: {
        fontSize: '4vh',
        fontWeight: 'bold',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    container: {
        width: "100%",
        height: "100%",
        backgroundColor: BG_transparent
    },
    infoImg: {
        width:"100%", 
        height:"100%",
        backgroundRepeat:"no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center"
    }
});


const IntroductionGame = (props: SurveyProps) => {
    let [appState, setAppState] = React.useState(AppState.GOAL)

    const onScan = () => {
        setAppState(AppState.SCAN)
    }
    const onApp = () => {
        setAppState(AppState.APP)
    }
    const onQRScan = () => {
        setAppState(AppState.START)
    }
    const onScanStart = () => {
        setAppState(AppState.SCAN_START)
    }
    const onLetsGo = () => {
        setAppState(AppState.LETS_GO)
    }

    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    }}>
        {appState === AppState.GOAL && <GoalScreen onNext={onScan}></GoalScreen>}
        {appState === AppState.SCAN && <ScanScreen onNext={onApp}></ScanScreen>}
        {appState === AppState.APP && <AppScreen onNext={onQRScan} withExpl={props.withExpl}></AppScreen>}
        {appState === AppState.START && <QRScanScreen onNext={onScanStart}></QRScanScreen>}
        {appState === AppState.SCAN_START && <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'relative',
            backgroundColor: BG_transparent_light,
            zIndex: -10
        }}><TakePhoto onScan={onLetsGo} resultList={["HOXAI-STRT"]}></TakePhoto></div>}
        {appState === AppState.LETS_GO && <LetsGoScreen onNext={props.onStart}></LetsGoScreen>}
    </div>
};

const LetsGoScreen = (props: {onNext: any}) => {
    const { t, i18n } = useTranslation();

    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.infoImg} style={{backgroundImage:`url("${i18n.language === "de" ? start_mushroom_DE : start_mushroom_EN}")`}}>
        <NextButton style={{
            position: 'absolute',
            right: '4vh',
            bottom: '4vh'
        }} onClick={props.onNext} text="letsGo"></NextButton>
        </div>
    </div>
}

const QRScanScreen = (props: {onNext: any}) => {
    const { t, i18n } = useTranslation();

    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.infoImg} style={{backgroundImage:`url("${i18n.language === "de" ? start_DE : start_EN}")`}}>
        <NextButton style={{
            position: 'absolute',
            right: '4vh',
            bottom: '4vh'
        }} onClick={props.onNext}></NextButton>
        </div>
    </div>
}

const GoalScreen = (props: {onNext: any}) => {
    const { t, i18n } = useTranslation();

    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.infoImg} style={{backgroundImage:`url("${i18n.language === "de" ? goal_DE : goal_EN}")`}}>
        <NextButton style={{
            position: 'absolute',
            right: '4vh',
            bottom: '4vh'
        }} onClick={props.onNext}></NextButton>
        </div>
    </div>
}

const ScanScreen = (props: {onNext: any}) => {
    const { t, i18n } = useTranslation();

    const classes = useStyles();

    return <div className={classes.container}>
    <div className={classes.infoImg} style={{
        backgroundImage:`url("${i18n.language === "de" ? scan_DE : scan_EN}")`}}>
        
        <NextButton style={{
            position: 'absolute',
            right: '4vh',
            bottom: '4vh'
        }} onClick={props.onNext}></NextButton>
        </div>
    </div>
}

type ImageStackProps = {
    className: string,
    urlList: string[] 
}

function ImageStack ({className, urlList} : ImageStackProps){
    const imageStack: JSX.Element[] = []
    urlList.forEach((layerUrl, idx) => {
        imageStack.push(<div key={`onboardingImageStack-${idx}`} className={className} style={{
            backgroundImage: `url("${layerUrl}")`,
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%'}}></div>)
    })
    return <div className={className} key="onboardingImageStack">{imageStack}</div>
}

const explSpecs = [
    ["intro"],
    ["01"],
    ["01", "02"],
    ["01", "02", "03"],
    ["01", "02", "03", "04", "04b"],
    ["01", "02", "03", "04", "05"],
    ["01", "02", "03", "04", "05", "06"],
    ["01", "02", "03", "04", "05", "06", "07"]
]

const noExplSpecs = [
    ["intro"],
    ["01"],
    ["01", "02"],
    ["01", "02", "03", "03b"],
    ["01", "02", "03", "04"],
    ["01", "02", "03", "04", "05"]
]

const AppScreen = (props: {onNext: any, withExpl: boolean}) => {
    const { t, i18n } = useTranslation();

    const [step, setStep] = useState(1);
    // const [imgUrl, setImgUrl] = useState();

    const languageString = i18n.language === "de" ? "DE" : "EN"
    const explString = props.withExpl ? "expl" : "noexpl"

    const makeScreenUrl = (spec: string) => {
        return require(`../images/introduction-screens/${explString}/forestly-${explString}-${spec}_${languageString}.png`).default
    }

    const makeUrlList = (specs: string[]) => {
        const result: string[] = []
        specs.forEach( s => result.push(makeScreenUrl(s)))
        return result
    }

    const [urlList, setUrlList] = useState([makeScreenUrl("03"), makeScreenUrl("01")]);

    const nextHint = () => {
        if(step >= (props.withExpl ? 8 : 6)){
            props.onNext()
        }else{
            setStep(step+1);
        }
    }

    useEffect(() => {
        setUrlList(makeUrlList(props.withExpl ? explSpecs[step-1] : noExplSpecs[step-1]))
    }, [step])
    
    // useEffect(()=>{
    //     setImgUrl(i18n.language === "de" ? require(`../images/introduction-screens/expl/forestly-expl-0${step}_DE.png`).default : require(`../images/introduction-screens/expl/forestly-expl-0${step}_EN.png`).default)
    // }, [step]);

    const baseScreenUrl = makeScreenUrl("base")

    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.infoImg} style={{backgroundImage:`url("${baseScreenUrl}")`}}>

            <ImageStack className={classes.infoImg} urlList={urlList} ></ImageStack>

            <NextButton style={{
                position: 'absolute',
                right: '4vh',
                bottom: '4vh'
            }} onClick={nextHint}></NextButton>
            </div>

        </div>
}

export default IntroductionGame;