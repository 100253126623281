import { makeStyles } from '@material-ui/core/styles';
import { MushroomItem } from './MushroomItem';
import { PredictionType } from '../types/types';



const useStyles = makeStyles({
    mushroomList: {
        overflowY: 'scroll',
        flex: '1 0 0',
        height: 0
    },
    mushroom: {
        background: 'red'
    }
});

type MushroomProps = {
    choice?: PredictionType
    explainer: boolean
}

export function MushroomExplainer({ choice, explainer }: MushroomProps) {
    const classes = useStyles();

    if (choice === undefined) return null;

    return <div className={classes.mushroomList}>
        {
            choice.prediction.predictions.sort((a, b) => b.probability - a.probability).map((prediction: any) => {
                return <MushroomItem choice={choice} prediction={prediction} explainer={explainer}></MushroomItem>
            })
        }
    </div>
}