import { Button, Grow, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Hand from './images/Hand-Mobile-Frame.png';
import { getHandy, getMushroomImage, PredictionType, User } from "./types/types";
import ButtonMitnehmen from './images/Pilz-Button-Mitnehmen.png';
import ButtonStehenlassen from './images/Pilz-Button-Stehen-Lassen.png';
import ButtonGiftig from './images/Pilz-Button-Nicht-Essbar.png';
import ButtonEssbar from './images/Pilz-Button-Essbar.png';
import { AppState, TakePhoto } from "./App";
import ScoreBasket from './images/Score-Basket.png';
import MushroomLayer1 from './images/Pilz-Layer-1-new.png';
import { BG_transparent, BG_transparent_light } from "./constants";

export type TasksProps = {
    availableMushrooms: string[]
    prediction?: PredictionType
    onClassify: (value: boolean[]) => void
    step: { step: number, maxSteps: number }
    user: User
    took: number
    onScan: any
    appState: any
    finishAnimation: string
    setFinishAnimation: any
}

export function TabletTasks({ availableMushrooms, prediction, onClassify, step, user, took, onScan, appState, finishAnimation, setFinishAnimation }: TasksProps) {
    const [classification, setClassification] = React.useState<boolean[]>([]);
    const [grow, setGrow] = React.useState(true);

    const { t, i18n } = useTranslation();

    const secondPress = (value: boolean) => {
        if (!grow) return;

        if (classification.length === 0) {
            setClassification([value])

            setGrow(false)
            setTimeout(() => {
                setGrow(true)
            }, 500)
        } else {
            if (value) {
                setFinishAnimation('out')
            } else {
                setFinishAnimation('dismiss')
            }


            setTimeout(() => {
                setFinishAnimation('none')
                onClassify([classification[0], value])
                setClassification([])
            }, 2000)
        }
    }

    React.useEffect(() => {
        if (prediction) {
            setFinishAnimation('in')

            setTimeout(() => {
                setFinishAnimation('stay')
            }, 2000)
        }
    }, [prediction?.prediction.id])

    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative'
    }}>
        <div style={{
            backgroundImage: `url("${ScoreBasket}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '12.5vh',
            height: '13.3vh',
            position: 'absolute',
            right: 0,
            top: 0
        }}>
            <Typography style={{
                position: 'absolute',
                top: '7.5vh', //8.3
                left: '0.9vh', // 1
                width: '3.5vh',
                textAlign: 'center'
            }}>{took}</Typography>
        </div>

        <div style={{
            backgroundColor: finishAnimation === 'stay' ? BG_transparent : BG_transparent_light,
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: -3,
        }}

        ></div>

        <div style={{
            backgroundImage: `url("${MushroomLayer1}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '102.43vh',
            height: '100vh',
            position: 'absolute',
            zIndex: -1,
            right: '-102.43vh',
            bottom: 0,
            animationDuration: '2s',
            animationFillMode: 'forwards',
            animationName: finishAnimation === 'out' || finishAnimation === 'dismiss' ? 'layerOut' : finishAnimation === 'in' || finishAnimation === 'stay' ? 'layerIn' : ''
        }}>
        </div>

        <div style={{
            position: 'absolute',
            height: '50vh',
            width: '50vh',
            right: '-50vh',
            top: '8vh',
            zIndex: -2,
            animationDuration: '2s',
            animationFillMode: 'forwards',
            animationName: finishAnimation === 'out' ? 'mushroom' : finishAnimation === 'in' || finishAnimation === 'stay' ? 'mushroomIn' : finishAnimation === 'dismiss' ? 'mushroomDismiss' : ''
        }}>
            <img alt={"Mushroom"}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                }}
                src={`${prediction ? getMushroomImage(prediction.prediction.id, i18n.language) : ''}`} />
        </div>


        {appState === AppState.Tasks && prediction ?
            <>
                <div style={{
                    width: 0,
                    flex: 1
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}>
                        <div style={{
                            backgroundImage: `url("${Hand}")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '76.25vh',
                            height: '100vh',
                            position: 'absolute'
                        }}>
                            <div style={{
                                backgroundImage: `url("${getHandy(prediction.prediction.id, i18n.language, user.explainer)}")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '76.25vh',
                                height: '100vh',
                                position: 'absolute',
                                zIndex: -1
                            }}></div>
                        </div>

                    </div>
                </div>

                <div style={{
                    width: 0,
                    flex: 1
                }}>
                    <div style={{
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                        justifyContent: 'flex-end'
                    }}>

                        {finishAnimation === 'stay' && <div style={{
                            flexShrink: 0,
                            display: 'grid',
                            // gridGap: '1rem',
                            gridTemplateAreas: `
                                "title title"
                                "nw ne"
                                "sw se"
                                `
                        }}>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gridArea: 'title'
                            }}>
                                <Grow in={grow}>
                                    <p style={{
                                        fontSize: "4vh",
                                        backgroundColor: 'rgba(31, 39, 60, 1)',
                                        padding: '0.5rem',
                                        borderRadius: '1rem',
                                    }}>{classification.length !== 0 ? t("task.willstPilzMitnehmen") : t("task.glaubstIstGiftig")}</p>
                                </Grow>
                            </div>
                            {
                                <>
                                    <div style={{
                                        gridArea: 'nw',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Grow in={grow}>
                                            <Button variant='text' style={{
                                                background: `url("${classification.length !== 0 ? ButtonStehenlassen : ButtonGiftig}") transparent no-repeat bottom center  / cover`,
                                                width: '20vh',
                                                height: '20vh'
                                            }} onClick={() => secondPress(false)}></Button>
                                        </Grow>
                                    </div>
                                    <div style={{
                                        gridArea: 'ne',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Grow in={grow}
                                            {...(grow ? { timeout: 1000 } : {})}
                                            style={{ transformOrigin: '0 0 0' }}
                                            >
                                                
                                            <Button variant='text' style={{
                                                background: `url("${classification.length !== 0 ? ButtonMitnehmen : ButtonEssbar}") transparent no-repeat bottom center  / cover`,
                                                width: '20vh',
                                                height: '20vh'
                                            }} onClick={() => secondPress(true)}></Button>
                                        </Grow>


                                    </div>
                                    <div style={{
                                        gridArea: 'sw',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Grow in={grow}>
                                            <p className={"decisionTxt"} style={{
                                                padding: '0.5rem',
                                                borderRadius: '1rem',
                                                backgroundColor: 'white',
                                                color: 'black',
                                            }}>{classification.length !== 0 ? t("task.stehenlassen") : t("giftig")}</p>
                                        </Grow>
                                    </div>

                                    <div
                                        className='flex-center'
                                        style={{
                                            gridArea: 'se',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            
                                        <Grow in={grow}>
                                            <p className={"decisionTxt"} style={{
                                                padding: '0.5rem',
                                                borderRadius: '1rem',
                                                backgroundColor: 'white',
                                                color: 'black',
                                            }}>{classification.length !== 0 ? t("task.mitnehmen") : t("essbar")}</p>
                                        </Grow>
                                    </div>
                                </>
                            }
                        </div>
                        }
                    </div>
                </div>
            </> :
            <TakePhoto onScan={onScan} resultList={availableMushrooms} openErrorDialog={true}></TakePhoto>}
    </div>
}