import { Card, Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { PredictionType } from "../types/types";

const useStyles = makeStyles({
    image: {
        width: '100%',
        flex: '0.6 0 0',
        height: 0,
        marginTop: '0.5rem'
    }
});

type MushroomImageProps = {
    choice?: PredictionType
    explainer: boolean
}

export function MushroomImage({ choice, explainer }: MushroomImageProps) {
    const classes = useStyles();
    const imgRef = React.useRef<any>();
    const [mode, setMode] = React.useState<'none' | 'cover' | 'contain'>('none');

    if (choice === undefined) return null;

    return explainer ?
        <div className={classes.image}>
            <Grid container>
                <Grid item xs={6}>
                    <Card style={{
                        width: '100',
                        height: '100%'
                    }}
                        elevation={5}>
                        <img alt={"Mushroom"}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }}
                            src={'data:image/jpeg;base64,' + choice.images[choice.prediction.image]} />
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card style={{
                        width: '100',
                        height: '100%'
                    }}
                        elevation={5}>
                        <img alt={"Mushroom"}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',

                            }}
                            src={'data:image/jpeg;base64,' + choice?.prediction.gradCamImg} />
                    </Card>
                </Grid>
            </Grid>
        </div> :
        <div className={classes.image}>
            <Card style={{
                width: '100',
                height: '100%'
            }}
                elevation={5}>
                <img alt={"Mushroom"}
                    ref={imgRef}
                    onLoad={() => {
                        // Image should use contain, since its horizontal aligned
                        if (imgRef.current.naturalWidth > imgRef.current.naturalHeight) {
                            setMode('cover')
                        } else {
                            setMode('contain')
                        }
                    }}
                    style={{
                        display: mode === 'none' ? 'none' : 'inherit',
                        width: '100%',
                        height: '100%',
                        objectFit: mode
                    }}
                    src={'data:image/jpeg;base64,' + choice.images[choice.prediction.image]} />
            </Card>
        </div>
}