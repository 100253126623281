import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import { Button, Container, Dialog, Grid, makeStyles } from '@material-ui/core';
import { PredictionType, User } from './types/types';
import { Finalize, TabletFinalize } from './components/Finalize';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmbedSurvey from './components/EmbedSurvey';

import ScanHand from './images/ScanHand-Mobile-Frame.png';
import { MushroomImage } from './components/MushroomImage';
import { MushroomExplainer } from './components/MushroomExplainer';
import { ExplainerSummary } from './components/ExplainerSummary';
import Stepper from './components/Stepper';
import QrScanner from 'qr-scanner';

import BtnDE1 from './images/Start_Language-DE-1.png';
import BtnDE2 from './images/Start_Language-DE-2.png';
import BtnEN1 from './images/Start_Language-EN-1.png';
import BtnEN2 from './images/Start_Language-EN-2.png';
import BtnStart from './images/Start-Button-Logo_02.png';

import MushroomError_DE from './images/mushroom-error_DE.png';
import MushroomError_EN from './images/mushroom-error_EN.png';



import './i18nextConf';
import { useTranslation } from 'react-i18next';
import { ParalaxBack } from './components/ParalaxBack';
import { BG_transparent } from './constants';
import IntroductionGame from './components/IntroductionGame';
import { TabletTasks, TasksProps } from './Tasks';
import { NextButton } from './components/NextButton';

import html2canvas from 'html2canvas';
import SearchIcon from '@material-ui/icons/Search';


// CONSTANTS
// const BASE_URL = "https://192.168.1.207:8080";
// const BASE_URL = "http://127.0.0.1:8080/";
const BASE_URL = ""; //for docker
// const BASE_URL = "https://hoxai.caleydoapp.org";

// const BASE_URL = "http://192.168.1.148:8080"


QrScanner.WORKER_PATH = '/static/qr-scanner-worker.min.js';

export enum AppState {
  Introduction,
  Survey,
  IntroductionGame,
  Tasks,
  TakingPhoto,
  Finish,
  SurveyPost
}




const useStyles = makeStyles({
  app: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    height: '100%'
  }
});






export function Tasks({ availableMushrooms, prediction, onClassify, step, user }: TasksProps) {
  const classes = useStyles();

  return <div className={classes.app}>
    <MushroomImage choice={prediction} explainer={user.explainer}></MushroomImage>
    <ExplainerSummary choice={prediction}></ExplainerSummary>
    <MushroomExplainer choice={prediction} explainer={user.explainer}></MushroomExplainer>
    <Stepper onClassify={onClassify} step={step}></Stepper>
  </div>
}


async function requestUserId(userId: string | null) {
  const response = await fetch(BASE_URL + '/user/create', {
    method: 'POST',
    body: JSON.stringify({ user: userId }),
    headers: { 'Content-Type': 'application/json' }
  });

  if (!response.ok) { /* Handle */ }

  // If you care about a response:
  if (response.body !== null) {
    const json = await response.json()
    return json
  }
}



function getUserId() {
  const urlParams = new URLSearchParams(window.location.search)
  const userParam = urlParams.get('user')

  return userParam
}

async function getNextPrediction(id: string) {
  const response = await fetch(BASE_URL + '/user/prediction', {
    method: 'POST',
    body: JSON.stringify({ id: id }),
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
  });

  if (!response.ok) { /* Handle */ }

  // If you care about a response:
  if (response.body !== null) {
    const json = await response.json()
    return json
  }
}


async function classifyAndNext(id: string, step: number, value: boolean[]) {
  const response = await fetch(BASE_URL + '/user/classify', {
    method: 'POST',
    body: JSON.stringify({ id: id, step: step, value: value }),
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
  });

  if (!response.ok) { /* Handle */ }

  // If you care about a response:
  if (response.body !== null) {
    const json = await response.json()
    return json
  }
}




function TabletIntroduction({ onStart }: IntroductionProps) {
  const { t, i18n } = useTranslation();

  const [srcDE, setSrcDE] = useState("");
  const [srcEN, setSrcEN] = useState("");

  const change_lang = (lang: string) => {
    i18n.changeLanguage(lang);
  

    setSrcDE(lang === "de" ? BtnDE2 : BtnDE1);
    setSrcEN(lang === "en" ? BtnEN2 : BtnEN1)

  };

  useEffect(() => {
    change_lang("de");
  }, []);


  return <Grid container style={{
    backgroundColor: BG_transparent,
    height: "100%",
    textAlign: "center"
  }} >
    <Grid item xs={2}></Grid>
    <Grid item xs={8} style={{ marginTop: "15vh" }}>
      <div
        style={{
          margin: "auto",
          width: '35vw',
          height: '80%',
          backgroundImage: `url("${BtnStart}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center'
        }}>
        <Button onClick={onStart} variant="text" style={{
          // borderRadius: '1rem',
          // background: 'white',
          color: 'black',
          fontWeight: 1000,
          fontSize: '8vh',
          paddingTop: '2vh',
          paddingBottom: '2vh',
          paddingLeft: '5vh',
          paddingRight: '5vh',
          marginTop: '35.5vh',
        }}>START</Button>
      </div>
    </Grid>
    <Grid item xs={2}>
      <div style={{
        marginTop: "5px",
        marginRight: "20px",
        width: "100%",
        height: "100%",
      }}>
        <img alt={"Deutsch"} className={"lang_icon"} src={srcDE} onClick={() => { change_lang("de") }}></img>
        <img alt={"English"} className={"lang_icon"} src={srcEN} onClick={() => { change_lang("en") }}></img>
      </div>
    </Grid>
  </Grid>
}

type TakePhotoProps = {
  onScan: (mushroomCode:string) => void;
  resultList?: string[];
  openErrorDialog?: boolean;
}

export function TakePhoto({ onScan, resultList, openErrorDialog }: TakePhotoProps) {
  const { t, i18n } = useTranslation();
  const [hasCam, setHasCam] = React.useState(true);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const calculateScanRegion = (video:any) => {
    const height = 400//video.videoHeight;
    const width = height * 0.5;
    console.log(height, width)
    return {
        x: Math.round((video.videoWidth - width) / 2),
        y: Math.round((video.videoHeight - height) / 2),
        width: width,
        height: height,
        downScaledWidth: width,
        downScaledHeight: height,
    };
  }

  React.useEffect(() => {
    QrScanner.hasCamera().then((value) => {
      if (value) {
        setHasCam(true);
        // webcam is available
        const elem = document.getElementById('videoElem') as HTMLVideoElement
        if (elem) {
          //@ts-ignore
          const qrScanner = new QrScanner(elem, (result: any) => {
            if (result) {
              if(resultList){ // if result list is not given, any qr code is ok
                if(resultList.includes(result)){ //otherwise, we only go to the next, if the result is included in the result list
                  onScan(result)
                  qrScanner.destroy()
                }else{
                  if(openErrorDialog)
                    setDialogOpen(true);
                }
              }else{
                onScan(result)
                qrScanner.destroy()
              }
            }
          }, undefined, calculateScanRegion, 'environment'); //calculateScanRegion

          qrScanner.start()

          const canvasElem = document.getElementById('canvasElem') as HTMLCanvasElement
          if(canvasElem){
            //@ts-ignore
            qrScanner.$canvas = canvasElem;
          }

          
        }
      } else {
        setHasCam(false);
      }
    });

  }, [])

  return <>
    <div style={{
      width: 0,
      flex: 1,
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <div style={{
          backgroundImage: `url("${ScanHand}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '76.3vh',
          height: '100vh',
          position: 'absolute'
        }}>
          <video id='videoElem' style={{
            display: "none"
          }}></video>
          <canvas id='canvasElem' style={{
            width:"43vh",
            height:"89vh",
            zIndex:-3,
            // transform: "scaleX(-1)",
            position: 'absolute',
            left: '21.3vh',
            top: '7.5vh',
          }}></canvas>
        </div>


        {!hasCam && <Button style={{
          position: 'absolute', zIndex: 5
        }} onClick={() => onScan("")}>Scan</Button>}

      </div>
      <Dialog PaperProps={{
        style: {
          backgroundColor: BG_transparent,
        },
        }} onClose={() => {setDialogOpen(false)}} aria-labelledby="simple-dialog-title" open={dialogOpen}>
        <img alt={"You already made a decision for this mushroom."} style={{width:"50vw"}} src={i18n.language === "de" ? MushroomError_DE : MushroomError_EN}/>
        <NextButton style={{
          position: 'absolute',
          right: '4vh',
          bottom: '4vh'
        }}
         onClick={() => { setDialogOpen(false); }} text="OK"></NextButton>
      </Dialog>
    </div>
  </>
}


// // magnify logic
// function init_magnify() {


//   /*Set the size of the magnifier glass:*/
//   const zoom = 2;
//   const width = 150;
//   const height = 150;
//   var root = document.getElementById("root");
//   root = (root ? root : document.body) as HTMLElement

//   var glass = document.createElement("DIV");
//   glass.setAttribute("class", "data-html2canvas-ignore img-magnifier-glass");

//   /* Insert magnifier glass: */
//   root.appendChild(glass);

//   /* Set background properties for the magnifier glass: */
//   glass.style.width = width + " px";
//   glass.style.height = height + " px";
//   // glass.style.backgroundImage = "url('" + data + "')";
//   glass.style.backgroundRepeat = "no-repeat";
//   glass.style.backgroundSize = "cover";
//   // glass.style.backgroundSize = (canvas.width * zoom) + "px " + (canvas.height * zoom) + "px";
//   var bw = 3;
//   var w = width / 2;
//   var h = height / 2;

//   /* Execute a function when someone moves the magnifier glass over the image: */
//   glass.addEventListener("mousemove", moveMagnifier);
//   root?.addEventListener("mousemove", moveMagnifier);

//   /*and also for touch screens:*/
//   glass.addEventListener("touchmove", moveMagnifier);
//   root?.addEventListener("touchmove", moveMagnifier);

//   function moveMagnifier(e: any) {
//     var pos, x, y;
//     /* Prevent any other actions that may occur when moving over the image */
//     e.preventDefault();
//     /* Get the cursor's x and y positions: */
//     pos = getCursorPos(e);
//     x = pos.x;
//     y = pos.y;
    
//     glass.style.left = (x - w) + "px";
//     glass.style.top = (y - h) + "px";

//     html2canvas(root as HTMLElement, {x:x-width/zoom/2, y:y-height/zoom/2, width:width/zoom, height:height/zoom,
//       ignoreElements: function (element) {
//           if (element.getAttribute("class")?.includes("img-magnifier-glass")) {
//               return true;
//           }else {
//               return false;
//           }
//       }}).then(canvas => {
//       // var glass = document.getElementsByClassName("img-magnifier-glass")[0] as HTMLElement;
//       // document.body.appendChild(canvas);
  
//       var data = canvas.toDataURL();
//       glass.style.backgroundImage = "url('" + data + "')";
//     });
//     /* Display what the magnifier glass "sees": */
//     // glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
//   }

//   function getCursorPos(e: any) {
//     var a, x = 0, y = 0;
//     e = e || window.event;
//     /* Get the x and y positions of the image: */
//     a = root?.getBoundingClientRect();
//     const a_left = a ? a.left : 0;
//     const a_top = a ? a.top : 0;
//     /* Calculate the cursor's x and y coordinates, relative to the image: */
//     x = e.pageX - a_left;
//     y = e.pageY - a_top;
//     /* Consider any page scrolling: */
//     x = x - window.pageXOffset;
//     y = y - window.pageYOffset;
//     return {x : x, y : y};
//   }
// }



function App() {
  
  const tabletLayout = true
  let [availableMushrooms, setAvailableMushrooms] = React.useState(Array.from(Array(31).keys()).map((v, i) => { return "HOXAI-"+String(v).padStart(3, '0');} )) //HOXAI-001
  let [stats, setStats] = React.useState({ poison: { took: 0, total: 0 }, good: { took: 0, total: 0 } })
  let [appState, setAppState] = React.useState(AppState.Introduction)
  let [prediction, setPrediction] = React.useState<PredictionType>()
  let [user, setUser] = React.useState<User>()
  let [step, setStep] = React.useState({
    step: 0,
    maxSteps: 0
  })
  let [loading, setLoading] = React.useState(true)

  let [took, setTook] = React.useState(0);

  const [finishAnimation, setFinishAnimation] = React.useState<'in' | 'out' | 'stay' | 'none' | 'dismiss'>('none');

  const onBackToStart = () => {
    setAvailableMushrooms(Array.from(Array(31).keys()).map((v, i) => { return "HOXAI-"+String(v).padStart(3, '0');} ))
    setStats({ poison: { took: 0, total: 0 }, good: { took: 0, total: 0 } })
    setAppState(AppState.Introduction)
    setPrediction(undefined)
    setUser(undefined)
    setStep({
      step: 0,
      maxSteps: 0
    })
    setLoading(true)
    setTook(0)

    onInit()
  }

  const onClassify = (value: boolean[]) => {
    setLoading(true)

    if (value[1]) {
      setTook(took + 1);
    }

    if (step.step === step.maxSteps) {
      // Finish
      classifyAndNext(user?.id as string, step.step, value).then((r) => {
        setStats(r)
        setLoading(false)
        setAppState(AppState.SurveyPost)
      })
    } else {
      if (tabletLayout) {
        classifyAndNext(user?.id as string, step.step, value).then((r) => {
          console.log(r.step)
          setLoading(false)
          setAppState(AppState.TakingPhoto)
        })
      } else {
        classifyAndNext(user?.id as string, step.step, value).then((r) => {
          console.log(r.step)
          setLoading(false)
          setPrediction(r)
          setStep({
            step: r.step,
            maxSteps: r.maxSteps
          })
        })
      }

    }
  }

  const onScan = (mushroomCode:string) => {
    setAvailableMushrooms((items) => items.filter((val, i) => val !== mushroomCode))
    setLoading(true)
    setAppState(AppState.Tasks)
    getNextPrediction(user?.id as string).then((r) => {
      setLoading(false)
      setPrediction(r)
      setStep({
        step: r.step,
        maxSteps: r.maxSteps
      })
    })
  }

  const onInit = () => {
    requestUserId(getUserId()).then(response => {
      setUser(response)
      console.log(response)

      if (response.finished) {
        // User has finished -> post survey
        setAppState(AppState.SurveyPost)
        setLoading(false)
      } else if (response.newlyCreated) {
        // User was newly created -> intro
        setAppState(AppState.Introduction)
        setLoading(false)
      } else {
        // User is in middle of tasks -> camera/tasks
        if (tabletLayout) {
          setLoading(false)
          setAppState(AppState.TakingPhoto)
        } else {
          setAppState(AppState.Tasks)

          getNextPrediction(response.id).then((r) => {
            setLoading(false)
            setPrediction(r)
            setStep({
              step: r.step,
              maxSteps: r.maxSteps
            })
          })
        }
      }
    })
  }

  React.useEffect(() => {
    onInit()
  }, [])

  

  const onStartSurvey = () => {
    setLoading(false)
    setAppState(AppState.Survey)
  }

  const onStartIntroToGame = () => {
    setAppState(AppState.IntroductionGame)
  }
  const onFinishGame = () => {
    setAppState(AppState.Finish)
  }

  const onStartTasks = () => { // after survey completed
    if (tabletLayout) {
      setLoading(false)
      setAppState(AppState.TakingPhoto)
    } else {
      setLoading(true)
      getNextPrediction(user?.id as string).then((r) => {
        setLoading(false)
        setPrediction(r)
        setAppState(AppState.Tasks)
        setStep({
          step: r.step,
          maxSteps: r.maxSteps
        })
      })
    }
  }


  if (tabletLayout) {
    return <>
      <div style={{
        width: '100%',
        height: '100%',
        zIndex: 0,
        position: 'relative'
      }}>
        {/* <SearchIcon style={{display:"absolute", left:"0px", right:"0px"}} onClick={() => {init_magnify()}}></SearchIcon> */}
        <ParalaxBack finishAnimation={finishAnimation}></ParalaxBack>

        <div style={{ width: '100%', height: '100%' }}>
          {appState === AppState.Introduction && <Suspense fallback={<div>loading</div>}><TabletIntroduction onStart={onStartSurvey}></TabletIntroduction></Suspense>}
          {appState === AppState.Survey && user && <EmbedSurvey onStart={onStartIntroToGame} userid={user.id} survey={"pre"}></EmbedSurvey>}
          {appState === AppState.IntroductionGame && user && <IntroductionGame onStart={onStartTasks} withExpl={user.explainer}></IntroductionGame>}
          {(appState === AppState.Tasks || appState === AppState.TakingPhoto) && user && <TabletTasks availableMushrooms={availableMushrooms} appState={appState} onScan={onScan} prediction={prediction} onClassify={onClassify} step={step} user={user} took={took} finishAnimation={finishAnimation} setFinishAnimation={setFinishAnimation}></TabletTasks>}
          {appState === AppState.SurveyPost && user && <EmbedSurvey onStart={onFinishGame} userid={user.id} survey={"post"}></EmbedSurvey>}
          {appState === AppState.Finish && <TabletFinalize stats={stats} backToStart={onBackToStart}></TabletFinalize>}
        </div>

        {
          loading && <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'absolute',
            zIndex: 10
          }}>
            <CircularProgress size='8rem' />
          </div>
        }
      </div>
    </>
  } else {
    return <>
      <Container style={{ height: '100%' }}>
        {loading ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}>
          <CircularProgress size='8rem' />
        </div> :
          <>
            {appState === AppState.Introduction && <Introduction onStart={onStartTasks}></Introduction>}
            {appState === AppState.Tasks && user && prediction && <Tasks availableMushrooms={[]} appState={appState} onScan={onScan} prediction={prediction} onClassify={onClassify} step={step} user={user} took={took} finishAnimation={finishAnimation} setFinishAnimation={setFinishAnimation}></Tasks>}
            {appState === AppState.Finish && <Finalize></Finalize>}
          </>
        }

      </Container>
    </>
  }
}






type IntroductionProps = {
  onStart: () => void
}


const Introduction = ({ onStart }: IntroductionProps) => {

  return <div style={{

  }}>
    <div>press here to start game...</div>
    <Button onClick={onStart}>Start Game</Button>

  </div>
}



export default App;



