import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'grid',
    gridTemplateAreas: `
    "title title title"
    "good arrows bad"
    `
  },
});

type StepperProps = {
  onClassify: (value: boolean[]) => void
  step: {step: number, maxSteps: number}
}

export default function Stepper({ onClassify, step }: StepperProps) {
  const classes = useStyles();

  return <div className={classes.root}>
    <div style={{
      gridArea: 'title'
    }}>
      <Typography align="center">Meine Entscheidung:</Typography>
    </div>
    <div style={{
      gridArea: 'bad',
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
      <Button style={{ background: 'red' }} onClick={() => onClassify([false])} variant="contained">Giftig</Button>
    </div>
    <div style={{
      gridArea: 'arrows',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <ChevronLeftIcon></ChevronLeftIcon>

      <Typography align="center">{step.step} / {step.maxSteps}</Typography>

      <ChevronRightIcon></ChevronRightIcon>
    </div>
    <div style={{
      gridArea: 'good'
    }}>
      <Button style={{ background: 'rgb(154, 222, 0)' }} onClick={() => onClassify([true])} variant="contained">Essbar</Button>
    </div>
  </div>
}